import React, { useEffect, useState } from "react";
import axios from 'axios'
import {
    Link,
    //    NavLink, useHistory, useLocation 
} from 'react-router-dom'
//import Swal from "sweetalert2";
import '../css/landing.css'
import { Collapse } from 'react-bootstrap';
//import Rightcontainer from "../Components/Rightcontainer";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { UserFooterBar } from "./UserFooterBar";

import '../Components/Component-css/Common.css';
import css from "../Components/Component-css/Nav.module.css";
import Profile1 from "./Profile1";

export default function Landing() {
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    var baseUrl;
    if (nodeMode === "development") {
        baseUrl = beckendLocalApiUrl;
    } else {
        baseUrl = beckendLiveApiUrl
    }
    const [open, setOpen] = useState(false);
    const [userAllData, setUserAllData] = useState();
    const [WebSitesettings, setWebsiteSettings] = useState("");

    const fetchData = async () => {
        const response = await fetch(baseUrl + "settings/data");
        const data = await response.json();
        console.log("Website setting ", data)
        return setWebsiteSettings(data);
    }
    const access_token = localStorage.getItem("token")
    const role = async () => {
        const access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        await axios.get(baseUrl + `me`, { headers })
            .then((res) => {
                setUserAllData(res.data)
            })
            .catch(e => {
                if (e.response.status == 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('token');
                    //window.location.href = "/login";
                    //window.location.reload()
                    //history.push("/login")
                }
            })

    }

    useEffect(() => {
        let access_token = localStorage.getItem('token');
        access_token = localStorage.getItem('token');
        if (!access_token) {
            //window.location.reload()
        }
        role();
        fetchData();
    }, [])






    return (
        <>
            {access_token &&
                <Header user={userAllData} />
            }


            <div className='leftContainer'>
                {userAllData && userAllData.verified != 'verified' &&
                    <div className="kyc_banner">
                        KYC Pending ?
                        <Link to='/kyc2' className='kyc_banner_link'>
                            Complete Here
                        </Link>
                    </div>
                }

                {/* {WebSitesettings && WebSitesettings.gameMsg &&
                    <div className="admin_message">
                        ◉ {WebSitesettings.gameMsg}
                    </div>
                } */}

                <div className='main-area'>

                    {/*KYC Pending Button*/}




                    {/*Banner Slider*/}

                    <Link to='/Refer'>
                        <div>
                            <OwlCarousel items={1}
                                className="owl-theme"
                                loop
                                nav={false}
                                dots={false}
                                autoplay={true}
                                autoplayTimeout={2000}
                                autoplayHoverPause={true}
                            >
                                <div className="home_slider">
                                {!WebSitesettings.ReferBanner && <img className="img" src={process.env.PUBLIC_URL + '/Images/' + process.env.REACT_APP_CHAT_SUPPORT_IMAGE + '/Reffer.png'} />}
                                {WebSitesettings.ReferBanner && <img className="img" src={baseUrl + WebSitesettings.ReferBanner} alt="" />}
                                </div>
                                <div className="home_slider">
                                {!WebSitesettings.ReferBanner && <img className="img" src={process.env.PUBLIC_URL + '/Images/' + process.env.REACT_APP_CHAT_SUPPORT_IMAGE + '/Reffer.png'} />}
                                {WebSitesettings.ReferBanner && <img className="img" src={baseUrl + WebSitesettings.ReferBanner} alt="" />}
                                </div>
                            </OwlCarousel>
                        </div>
                    </Link>

                    { /* WebSitesettings.homeMsg &&
                  <div className="gameCard mr-3 ml-3 mt-1">
                    <div className="collapseCard">
                        <div
                          className="collapseCard-body bg-danger"
                          style={{ height: "60px", opacity: 1 }}
                        >
                          <div className=" collapseCard-text ">
                            {" "}
                            <strong className=" text-white">
                              ◉ {WebSitesettings.homeMsg}
                            </strong>
                          </div>
                        </div>
                    </div>
                  </div> */
                    }


                    {WebSitesettings.homeMsg &&
                        <div className="kyc_banner">
                            ◉ {WebSitesettings.homeMsg}
                        </div>
                    }

                    {/*Game Section*/}
                    <section className="games-section">
                        <div className="games-section-title-2">Our Tournaments</div>
                        <div className="games-window ">
                            <div className="game_card_section">
                                <Link className="gameCard-container" to={`/Homepage/LudoManual`} >
                                    {/* <span className="d-none blink text-danger d-block text-right">◉ LIVE</span> */}
                                    <picture className="gameCard-image">
                                        {!WebSitesettings.LandingImage1 && <img width="100%" src={process.env.PUBLIC_URL + '/Images/' + process.env.REACT_APP_CHAT_SUPPORT_IMAGE + '/LudoClassic.png'} alt=""
                                            style={{ boxShadow: 'rgba(2, 2, 2, 0.9) 0px 10px 10px -10px;' }} />}
                                        {WebSitesettings.LandingImage1 && <img width="100%" src={baseUrl + WebSitesettings.LandingImage1} alt=""
                                            style={{ boxShadow: 'rgba(2, 2, 2, 0.9) 0px 10px 10px -10px;' }} />}

                                    </picture>

                                </Link>

                                <Link className="gameCard-container" to={`/Homepage/LudoUltaManual`}>
                                    {/* <span className="d-none blink text-danger d-block text-right">◉ LIVE</span> */}
                                    <picture className="gameCard-image">
                                        {!WebSitesettings.LandingImage2 && <img width="100%" src={process.env.PUBLIC_URL + '/Images/' + process.env.REACT_APP_CHAT_SUPPORT_IMAGE + '/LudoClassicUlta.png'} alt=""
                                            style={{ boxShadow: 'rgba(2, 2, 2, 0.9) 0px 10px 10px -10px;' }} />}
                                        {WebSitesettings.LandingImage2 && <img width="100%" src={baseUrl + WebSitesettings.LandingImage2} alt=""
                                            style={{ boxShadow: 'rgba(2, 2, 2, 0.9) 0px 10px 10px -10px;' }} />}
                                    </picture>
                                </Link>
                            </div>
                            <div className="game_card_section">
                                <Link className="gameCard-container" to={`/Homepage/snakgame`}>
                                    {/* Ludo%20Popular <span className="d-none blink text-danger d-block text-right">◉ LIVE</span> */}
                                    <picture className="gameCard-image">
                                        {!WebSitesettings.LandingImage3 && <img width="100%" src={process.env.PUBLIC_URL + '/Images/' + process.env.REACT_APP_CHAT_SUPPORT_IMAGE + '/SnakeClasssic.png'} alt=""
                                            style={{ boxShadow: 'rgba(2, 2, 2, 0.9) 0px 10px 10px -10px;' }} />}
                                        {WebSitesettings.LandingImage3 && <img width="100%" src={baseUrl + WebSitesettings.LandingImage3} alt=""
                                            style={{ boxShadow: 'rgba(2, 2, 2, 0.9) 0px 10px 10px -10px;' }} />}
                                    </picture>
                                </Link>
                                <Link className="gameCard-container" to={`/Homepage/snakultagame`}>
                                    {/* <span className="d-none blink text-danger d-block text-right">◉ LIVE</span> */}
                                    <picture className="gameCard-image">
                                        {!WebSitesettings.LandingImage4 && <img width="100%" src={process.env.PUBLIC_URL + '/Images/' + process.env.REACT_APP_CHAT_SUPPORT_IMAGE + '/SnakeClasssic_Ulta.png'} alt=""
                                            style={{ boxShadow: 'rgba(2, 2, 2, 0.9) 0px 10px 10px -10px;' }} />}
                                        {WebSitesettings.LandingImage4 && <img width="100%" src={baseUrl + WebSitesettings.LandingImage4} alt=""
                                            style={{ boxShadow: 'rgba(2, 2, 2, 0.9) 0px 10px 10px -10px;' }} />}
                                    </picture>
                                </Link>
                            </div>
                            <div className="game_card_section">
                                <Link className="gameCard-container" to={`/support`} >
                                    {/* <span className="d-none blink text-danger d-block text-right">◉ Comming Soon</span> */}
                                    <picture className="gameCard-image">
                                        {!WebSitesettings.BottomBanner && <img width="100%" src={process.env.PUBLIC_URL + '/Images/' + process.env.REACT_APP_CHAT_SUPPORT_IMAGE + '/ChatSupport.png'} alt="" />}
                                        {WebSitesettings.BottomBanner && <img width="100%" src={baseUrl + WebSitesettings.BottomBanner} alt="" />}

                                    </picture>

                                </Link>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </>
    )
}