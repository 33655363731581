import React from 'react'
import css from "../Modulecss/Home.module.css";
import { Link } from "react-router-dom";

export default function RunningCard({runnig,user,winnAmount,game_type}) {
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    if (nodeMode === "development") {
      var baseUrl = beckendLocalApiUrl;
    } else {
      baseUrl = beckendLiveApiUrl;
    }
    return (
        <div className={`${css.betCard} mt-1 card`} 
      >
            
                <div className={`${css.betcard_user_info}`}>
            
                    <div className={`${css.betCard_user_info}`}>
                       
                            PLAYING FOR
                       
                        <i class="fa-solid fa-indian-rupee-sign" style={{paddingLeft: '0.3rem'}}></i>
                        {runnig.Game_Ammount}
                       
                    </div>
                    <div className='mid'></div>
                    <div className={`${css.betCard_user_info}`}>
                            PRIZE
                            
                                <i class="fa-solid fa-indian-rupee-sign" style={{paddingLeft: '0.3rem'}}></i>
                        
                                {runnig.Game_Ammount +
                                    winnAmount(runnig.Game_Ammount)}
                            
                    </div>

                </div>


                <div 
                className={`${css.betcard_user_set}`}
                >
                    <div >
                        <div className='' style={{marginBottom: '0.5rem'}}>
                            {runnig.Created_by.avatar ? (<img
                                src={baseUrl+`${runnig.Created_by && runnig.Created_by.avatar}`} onError={(e)=>{e.target.onerror = null; e.target.src="https://rkludo.in/user.png"}}
                                alt=""
                                width='35px' height="35px"
                                style={{ borderTopLeftRadius: "50%", borderTopRightRadius: "50%", borderBottomRightRadius: "50%", borderBottomLeftRadius: "50%",
                                }}
                            />) : (<img
                                src={`https://rkludo.in/user.png`}
                                alt=""
                                width='35px' height="35px"
                                style={{ borderTopLeftRadius: "50%", borderTopRightRadius: "50%", borderBottomRightRadius: "50%", borderBottomLeftRadius: "50%" }}
                            />)}
                        </div>
                        <div style={{ lineHeight: 1 }} className='text-center'>
                            <span className={css.betCard_playerName}>
                                {runnig.Created_by.Name}
                            </span>
                        </div>
                    </div>

                    <div className='mid'>
                        <div>
                            <img
                                src={process.env.PUBLIC_URL + '/Images/kd_ludo/versus.png'}
                                alt=""
                                width="60px"
                            />
                        </div>
                    </div>
                    
                    <div>
                        <div className='text-center' style={{marginBottom: '0.5rem'}}>
                            {runnig.Accepetd_By.avatar ? (<img
                                src={baseUrl+`${runnig.Accepetd_By && runnig.Accepetd_By.avatar}`} onError={(e)=>{e.target.onerror = null; e.target.src="https://rkludo.in/user.png"}}
                                alt=""
                                width='35px' height="35px"
                                style={{ borderTopLeftRadius: "50%", borderTopRightRadius: "50%", borderBottomRightRadius: "50%", borderBottomLeftRadius: "50%" }}
                            />) : (<img
                                src={`https://rkludo.in/user.png`}
                                alt=""
                                width='35px' height="35px"
                                style={{ borderTopLeftRadius: "50%", borderTopRightRadius: "50%", borderBottomRightRadius: "50%", borderBottomLeftRadius: "50%" }}
                            />)}
                        </div>
                        <div style={{ lineHeight: 1 }}>
                            <span className={css.betCard_playerName}>
                                {runnig.Accepetd_By.Name}
                            </span>
                        </div>
                    </div>
                </div>

           
                {(user == runnig.Accepetd_By._id ||
                        user == runnig.Created_by._id) && (
                            <Link
                                className={`${css.betcardbtn} ${runnig.Status=="conflict"?'bg-danger':'bg-success'}`}
                            
                                to={{ pathname: `/viewgame1/${runnig._id}`, state: { prevPath: window.location.pathname } }}
                            >
                                View
                            </Link>
                )}
          
            </div>
       
    )
}